<template>
  <Teleport v-show="undos.length" to="body">
    <div
      :class="{ 'pointer-events-none': !undos.length }"
      class="fixed right-0 top-0 z-[999999] w-full px-8 py-4 md:bottom-4 md:top-auto md:w-[350px] lg:w-[500px]"
    >
      <ul class="relative">
        <TransitionGroupHelper
          :enter="
            navigator.userAgent.includes('Mobile')
              ? {
                  translateY: [20, 0],
                  opacity: [0, 1],
                }
              : {
                  translateY: [50, 0],
                  opacity: [0, 1],
                }
          "
          :leave="
            navigator.userAgent.includes('Mobile')
              ? {
                  translateY: [0, 50],
                  opacity: [1, 0],
                }
              : {
                  translateY: [0, 50],
                  opacity: [1, 0],
                }
          "
        >
          <li
            v-for="undo in undos"
            :key="undo.id"
            class="bottom-0 flex w-full flex-col gap-2 rounded bg-white p-2 text-sm shadow-lg dark:bg-black dark:shadow-none dark:ring-1 dark:ring-neutral-700"
          >
            <ul class="flex flex-col gap-y-2">
              <li
                v-for="(change, index) in undo.changes.slice(0, 3)"
                :key="index"
              >
                <EntryDisplay
                  :modelValue="change.after"
                  :entry="change.after"
                  :display="{
                    custom_fields: false,
                    settings: false,
                  }"
                  :disabled="true"
                  position="undo"
                  renderType="list"
                  class="w-full flex-1 rounded-lg p-2 ring-1 ring-inset ring-neutral-300 dark:ring-neutral-700"
                />
              </li>
              <li
                v-if="undo.changes.length > 3"
                class="bg-neutral-50 dark:bg-neutral-950"
              >
                + {{ undo.changes.length - 3 }} More
              </li>
            </ul>
            <div class="flex justify-between gap-2 md:justify-end">
              <ButtonComponent @click="[undo.close()]" color="secondary">
                Close
              </ButtonComponent>
              <ButtonComponent @click="[undo.undo()]" color="primary">
                <ArrowUturnLeftIcon class="w-4" /> Undo
              </ButtonComponent>
            </div>
          </li>
        </TransitionGroupHelper>
      </ul>
    </div>
  </Teleport>
</template>

<script>
import { ArrowUturnLeftIcon } from "@heroicons/vue/24/outline";
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";

export default {
  name: "UndosComponent",
  data() {
    return {
      navigator: navigator,
    };
  },
  computed: {
    undos() {
      return this.$store.getters.undos;
    },
  },
  components: {
    ArrowUturnLeftIcon,
  },
  mixins: [Statusing, Scheduling],
};
</script>
