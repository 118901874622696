<template>
  <div class="flex flex-col gap-y-1">
    <draggable
      class="list-group flex flex-col gap-y-1"
      v-model="custom_fields"
      group="custom_fields"
      handle="[data-handle]"
      @change="updatePositions"
      itemKey="name"
    >
      <template #item="{ element }">
        <div
          class="flex flex-wrap items-start gap-2"
          :data-test="'cf_' + $slugify(element.name)"
        >
          <a
            data-handle
            class="flex cursor-move gap-1 self-center"
            data-test="status_position_handle"
          >
            <ChevronUpDownIcon
              class="h-5 w-5 text-neutral-900 dark:text-neutral-400"
            />
          </a>
          <TextInput
            v-model="element.name"
            @update="updateCustomField(element)"
            class="flex-1"
          />
          <OptionPicker
            v-model="element.type"
            @update:modelValue="
              (type) => {
                element.type = type;
                if (['rating'].includes(type)) {
                  element.config = { count: 5, char: '⭐️' };
                } else {
                  element.config = {};
                }
                updateCustomField(element);
              }
            "
            :options="['text', 'number', 'rating']"
            :size="size"
            :variant="variant"
            :color="color"
          />
          <ButtonComponent
            @click="deleteCustomField(element)"
            :size="size"
            :variant="variant"
            :color="color"
            :data-test="'delete_status_' + $slugify(element.name)"
          >
            <TrashIcon class="h-5" />
          </ButtonComponent>
          <div
            v-if="['rating'].includes(element.type)"
            class="basis-full rounded-md p-2 ring-1 ring-neutral-200"
          >
            <div v-if="element.config" class="flex items-center gap-2">
              <LabelHelper>Emoji</LabelHelper>
              <TextInput
                v-model="element.config.char"
                @update:modelValue="
                  (char) => {
                    element.config = { ...element.config, char: char };
                    updateCustomField(element);
                  }
                "
                class="w-12 text-xs"
              />
              <LabelHelper>How many</LabelHelper>
              <OptionPicker
                :modelValue="element.config.count.toString()"
                @update:modelValue="
                  (count) => {
                    element.config = { ...element.config, count: count };
                    updateCustomField(element);
                  }
                "
                :options="['3', '5', '10']"
              />
            </div>
          </div>
        </div>
      </template>
    </draggable>

    <div class="flex flex-wrap items-start gap-2" data-test="status_new_status">
      <a data-handle class="hidden cursor-move gap-1 self-center">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-900" />
      </a>

      <TextInput
        v-model="newCF.name"
        @onEnter="addCustomField"
        :clearOnEnter="true"
        class="flex-1"
        placeholder="Custom Field Name"
        data-test="cf_new_cf_name"
      />

      <OptionPicker
        v-model="newCF.type"
        :options="['text', 'number', 'rating']"
        @update:modelValue="
          (type) => {
            newCF.type = type;
            if (['rating'].includes(type)) {
              newCF.config = { count: 5, char: '⭐️' };
            } else {
              newCF.config = null;
            }
          }
        "
        :size="size"
        :variant="variant"
        :color="color"
        data-test="cf_new_cf_type"
      />
      <ButtonComponent
        @click="addCustomField"
        data-test="cf_new_cf_add"
        :size="size"
        :variant="variant"
        :color="color"
      >
        <PlusIcon class="h-5" />
      </ButtonComponent>

      <div
        v-if="['rating'].includes(newCF.type)"
        class="basis-full rounded-md p-2 ring-1 ring-neutral-200"
      >
        <div v-if="newCF.config" class="flex items-center gap-2">
          <LabelHelper>Emoji</LabelHelper>
          <TextInput
            v-model="newCF.config.char"
            @update:modelValue="
              (char) => {
                newCF.config = { ...newCF.config, char: char };
              }
            "
            class="w-12 text-xs"
          />
          <LabelHelper>How many</LabelHelper>
          <OptionPicker
            :modelValue="newCF.config.count.toString()"
            @update:modelValue="
              (count) => {
                newCF.config = { ...newCF.config, count: count };
              }
            "
            :options="['3', '5', '10']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronUpDownIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline";
import TextInput from "../helpers/TextInput.vue";
import { customFieldBlueprint } from "@/addonBlueprint";

import draggable from "vuedraggable";
import OptionPicker from "../builders/OptionPicker.vue";
import { Addon } from "../mixins/Addon";

export default {
  mixins: [Addon],
  computed: {
    space() {
      return this.$store.getters.space;
    },
    custom_fields: {
      get() {
        return this.modelValue;
      },
      set(custom_fields) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.custom_fields = custom_fields;

        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
  components: {
    TextInput,
    TrashIcon,
    PlusIcon,
    draggable,
    ChevronUpDownIcon,
    OptionPicker,
  },
  data() {
    return {
      newCF: customFieldBlueprint(this.entry, this),
    };
  },
  methods: {
    updatePositions: function () {
      this.custom_fields = this.custom_fields.map((cf, i) => {
        cf.position = i;
        return cf;
      });
    },
    addCustomField() {
      if (this.newCF.name.length === 0) {
        return;
      }

      // Set position of new status
      this.newCF.position = this.custom_fields.length;
      this.custom_fields = [...this.custom_fields, this.newCF];

      //this.space.statuses.push(this.newCF);
      this.newCF = customFieldBlueprint(this.entry, this);
    },
    updateCustomField(custom_field) {
      this.$store.dispatch("push", {
        event: "custom_field_update",
        params: { custom_field: custom_field },
        entry: this.entry,
      });

      // this.space.statuses = this.space.statuses.map((s) => {
      //   if (s.id === status.id) {
      //     return status;
      //   }
      //   return s;
      // });
    },
    deleteCustomField(custom_field) {
      // this.$store.dispatch("push", {
      //   event: "custom_field_delete",
      //   params: { id: custom_field.id },
      //   entry: this.entry,
      // });
      this.custom_fields = this.custom_fields
        .filter((cf) => cf.id !== custom_field.id)
        .map((cf, i) => {
          cf.position = i;
          return cf;
        });
      // Find all entries with that status and remove
      // TODO: this needs to be adjusted for cfs
      // this.$store.getters.entries
      //   .filter((e) => e.status_id === status.id)
      //   .forEach((e) => {
      //     e.status_id = null;
      //     this.$store.dispatch("push", {
      //       event: "entry_update",
      //       params: { entry: e },
      //       entry: e,
      //     });
      //   });

      //this.statuses = this.statuses.filter((s) => s.id !== status.id);
    },
  },
};
</script>
