<template>
  <div
    id="application-header"
    class="pointer-events-none flex items-center justify-between"
  >
    <BulkSelectMenu
      v-if="!$route.params.id2 && $store.getters.selected.length"
      :class="{
        'shadow-lg ring-1 ring-inset ring-neutral-100 dark:shadow-none dark:ring-neutral-700':
          scrollY > 15,
      }"
      class="pointer-events-auto -translate-x-2 rounded-lg bg-white/75 px-2 py-2.5 backdrop-blur transition-all sm:rounded-full dark:bg-black/75"
    />
    <template v-else>
      <div
        :class="{
          'shadow-lg ring-1 ring-inset ring-neutral-100 dark:shadow-none dark:ring-neutral-700':
            scrollY > 15,
        }"
        class="pointer-events-auto max-w-fit -translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"
      >
        <Transition @enter="animateEnter" @leave="animateLeave">
          <div
            v-if="$route.meta.showSidebar !== false"
            class="hidden items-center gap-x-4 p-2 sm:flex"
          >
            <div class="flex gap-x-2">
              <ButtonComponent
                ref="left_sidebar_toggle_mobile"
                id="left_sidebar_toggle_mobile"
                variant="round"
                color="neutral"
                @click="$emit('toggleSidebarMobile')"
                data-test="left_sidebar_toggle_mobile"
                :class="{
                  // '-m-2 rounded-lg p-2 ring-2 ring-blue-400':
                  //   $store.getters.dragged?.length,
                }"
                class="hidden !bg-neutral-50 !text-neutral-800 hover:!bg-neutral-100 hover:!text-black sm:block md:hidden dark:!bg-neutral-950 dark:!text-neutral-200 dark:hover:!bg-neutral-900 dark:hover:!text-neutral-100"
                @mouseenter="
                  (e) => {
                    if ($store.getters.dragged?.length) {
                      $emit('toggleSidebarMobile');
                      // $refs.left_sidebar_toggle_mobile.click();
                    }
                  }
                "
                title="Toggle sidebar"
                shortcode="m"
              >
                <span class="sr-only">Open sidebar</span>

                <PanelLeftIcon stroke-width="1.25" aria-hidden="true" />
              </ButtonComponent>

              <ButtonComponent
                variant="round"
                color="secondary"
                size="lg"
                class="hidden md:block"
                @click="$emit('toggleSidebar', true)"
                @mouseenter="
                  (e) => {
                    if ($store.getters.dragged?.length) {
                      $emit('toggleSidebar', false);
                      // sidebarDesktopOpen = true;
                    }
                  }
                "
                title="Toggle sidebar"
                shortcode="m"
              >
                <span class="sr-only">Open sidebar</span>
                <IconHelper
                  v-if="sidebarDesktopOpen"
                  name="PanelLeftCloseIcon"
                  size="24"
                />
                <IconHelper v-else name="PanelLeftOpenIcon" size="24" />
              </ButtonComponent>

              <div v-if="3 > 4" class="group/back relative">
                <ButtonComponent
                  variant="round"
                  class="dark:!text-neutral-20 dark:hover:!text-neutral-1000 !bg-neutral-50 !text-neutral-800 hover:!bg-neutral-100 hover:!text-black dark:!bg-neutral-950 dark:!text-neutral-200 dark:hover:!bg-neutral-900 dark:hover:!text-neutral-100"
                  @click="
                    // go back in history
                    $window.history.back()
                  "
                >
                  <span class="sr-only">Go back</span>
                  <ChevronLeftIcon class="m-0.5 h-4 w-4" aria-hidden="true" />
                </ButtonComponent>

                <div
                  class="pointer-events-none absolute -bottom-2 left-0 z-10 w-max translate-y-full truncate rounded-md bg-white px-2 py-1 text-sm opacity-0 drop-shadow-md group-hover/back:block group-hover/back:opacity-100 group-hover/back:transition-opacity group-hover/back:delay-300"
                >
                  Go back
                  <span
                    v-if="!navigator.userAgent.includes('Mobile')"
                    :class="[
                      'pointer-events-none flex-none text-xs font-semibold text-neutral-500',
                    ]"
                  >
                    <kbd class="font-sans">{{ $metaKey }}</kbd>
                    <kbd class="font-sans">[</kbd>
                  </span>
                </div>
              </div>
              <button
                v-if="3 > 4"
                type="button"
                class="-m-2.5 hidden p-2.5 text-gray-700 lg:block"
                @click="
                  // go forward
                  $window.history.forward()
                "
                :style="{
                  display:
                    $window.history && $window.history.state ? 'none' : null, //$window.history.length <= 1,
                }"
              >
                <span class="sr-only">Go forward</span>
                <ChevronRightIcon class="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
            <!-- Separator -->
            <div
              class="hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800"
              aria-hidden="true"
            />

            <!-- Start: Command Palette -->
            <div
              class="flex justify-center gap-x-2 self-stretch md:gap-x-4 lg:gap-x-6"
            >
              <ButtonComponent
                @click="$emit('toggleCommandPalette')"
                color="white"
                size="lg"
                class="relative hidden w-[200px] flex-1 items-center self-center !rounded-full bg-white ring-1 ring-inset ring-neutral-200 sm:flex dark:ring-neutral-700"
                data-test="search_main"
              >
                <SearchIcon size="24" stroke-width="1.75" aria-hidden="true" />
                Search
                <span
                  v-if="!navigator.userAgent.includes('Mobile')"
                  :class="[
                    'pointer-events-none absolute right-4 flex-none text-xs font-semibold text-neutral-500',
                  ]"
                >
                  <kbd class="font-sans">{{ $metaKey }}</kbd>
                  <kbd class="font-sans">K</kbd>
                </span>
                <!--<input
                id="search-field"
                class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
              />-->
              </ButtonComponent>
            </div>
            <!-- End: Command Palette -->

            <!-- Separator -->
            <div
              v-if="
                $store.getters.tours?.find(
                  (t) => t.hook == 'application_header',
                )
              "
              class="hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800"
              aria-hidden="true"
            />

            <TourHelper hook="application_header" />
          </div>
        </Transition>
      </div>

      <div
        :class="{
          'shadow-lg ring-1 ring-inset ring-neutral-100 dark:ring-neutral-700':
            scrollY > 15,
        }"
        class="pointer-events-auto max-w-fit translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"
      >
        <div class="flex items-center gap-x-4 p-2">
          <!-- Time trackings dropdown-->
          <HeaderTimeTrackingComponent v-if="isShowTimeTrackings" />

          <!-- Separator -->
          <div
            v-if="isShowTimeTrackings"
            class="hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800"
            aria-hidden="true"
          />
          <!-- Profile dropdown -->
          <Menu as="div" class="relative">
            <MenuButton class="focus-visible:outline-0">
              <ButtonComponent
                color="secondary"
                variant="round"
                size="lg"
                data-test="user_name"
                title="Open user menu"
              >
                <span class="sr-only">Open user menu</span>
                <!--<img
                    class="h-8 w-8 rounded-full bg-neutral-50"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />-->
                <!-- <UserIcon class="h-6 w-6" /> -->
                <IconHelper name="user" size="24" />

                <!-- <span class="hidden md:flex md:items-center">
                <span
                  class="ml-4 whitespace-nowrap text-sm font-semibold leading-6 text-neutral-900 hover:text-neutral-700 dark:text-neutral-200 dark:hover:text-white"
                  aria-hidden="true"
                  data-test="user_name"
                >
                  {{ user?.name }}
                </span>
                <ChevronDownIcon
                  class="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span> -->
              </ButtonComponent>
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-black dark:ring-neutral-100/10"
              >
                <MenuItem v-slot="{ active }">
                  <a
                    @click="$router.push({ name: 'profile' })"
                    :class="[
                      active ? 'bg-gray-50 dark:bg-neutral-900' : '',
                      'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300',
                    ]"
                    data-test="user_name"
                  >
                    Profile
                  </a>
                </MenuItem>

                <Menu as="div" class="relative w-full">
                  <MenuButton
                    as="button"
                    data-test="help_button"
                    class="w-full focus-visible:outline-0"
                  >
                    <MenuItem v-slot="{ active }">
                      <div
                        :class="[
                          active ? 'bg-gray-50 dark:bg-neutral-900' : '',
                          'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 dark:text-neutral-300',
                        ]"
                      >
                        Help
                      </div>
                    </MenuItem>
                  </MenuButton>
                  <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-black dark:ring-neutral-100/10"
                    >
                      <MenuItem
                        v-for="(item, index) in helpItems"
                        :key="index"
                        as="div"
                        v-slot="{ active }"
                      >
                        <a
                          :href="item.url"
                          target="_blank"
                          :class="[
                            active ? 'bg-gray-50 dark:bg-neutral-900' : '',
                            'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300',
                          ]"
                        >
                          {{ item.title }}
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
                <MenuItem v-slot="{ active }">
                  <router-link
                    v-if="token"
                    :to="{ name: 'logout' }"
                    :class="[
                      active ? 'bg-gray-50 dark:bg-neutral-900' : '',
                      'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300',
                    ]"
                  >
                    Logout
                  </router-link>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PanelLeftIcon, SearchIcon } from "lucide-vue-next";
</script>

<!-- eslint-disable vue/no-unused-components -->
<script>
import Toasts from "./helpers/Toasts.vue";
import Undos from "./helpers/Undos.vue";
import Unverified from "./Unverified.vue";

import { Crud } from "./mixins/Crud";
import HeaderTimeTrackingComponent from "./partials/HeaderTimeTrackingComponent.vue";

export default {
  provide() {
    return {
      position: "shell",
    };
  },

  props: {
    sidebarDesktopOpen: Boolean,
  },
  components: {
    Toasts,
    Undos,
    Unverified,
  },
  mixins: [Crud],
  computed: {
    mainFrame() {
      return document.querySelector("#main");
    },
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },

    entry() {
      return this.entries.find((e) => e.id == this.$route.params.id);
    },
    space() {
      return this.$store.getters.space;
    },
    isShowTimeTrackings() {
      return this.entries.filter((e) => {
        return (
          e.time_trackings.length &&
          e.time_trackings.filter(
            (tt) =>
              tt.user_id == this.user.id &&
              this.$moment.utc(tt.start_at).format("YYYY-MM-DD") ==
                this.$moment().format("YYYY-MM-DD"),
          )
        ).length;
      }).length;
    },
  },
  created() {},
  data() {
    return {
      navigator: navigator,
      scrollY: 0,
      helpItems: [
        { url: "https://help.pensive.io", title: "Help Section" },
        // {
        //   url: "https://help.pensive.io/best-practices",
        //   title: "Best Practices",
        // },
        {
          url: "https://forms.gle/B5hi53AM9PZsR4jz5",
          title: "Report a Bug",
        },
        {
          url: "https://forms.gle/PQXmMKPwGQQhAin7A",
          title: "Request a Feature",
        },
        {
          url: "https://pensive.io/whats-new",
          title: "Whats new in v." + this.$version,
        },
      ],
    };
  },
  mounted() {
    this.pullData();
    if (this.mainFrame)
      this.mainFrame.addEventListener("scroll", this.onScroll);
    this.onScroll();
    this.$nextTick(() => {
      this.$emit("mounted");
    });
  },
  beforeUnmount() {
    if (this.mainFrame)
      this.mainFrame.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    pullData() {
      this.$store.dispatch("pull", {
        filters: [
          {
            key: ["time_trackings", "start_at"],
            op: "eq",
            val: [this.$moment().format("YYYY-MM-DD")],
          },
        ],
      });
    },

    onScroll() {
      if (this.mainFrame) this.scrollY = this.mainFrame.scrollTop;
    },
    animateEnter(el, done) {
      this.$anime({
        targets: el,
        translateY: [-100, 0],
        opacity: [0, 1],
        complete: done,
      });
    },
    animateLeave(el, done) {
      this.$anime({
        targets: el,
        translateY: [0, -100],
        opacity: [1, 0],
        complete: done,
      });
    },
  },
};
</script>
