<template>
  <div
    :data-position="position"
    :data-active="isActive"
    ref="wrapper"
    class="group relative"
    :class="{
      [$style.entryDisplay]: true,
      'pointer-events-none': entry.deleted_at,
      [$style.entryDisplay_selected]:
        position != 'draggable' && isSelected && !isDragged,
      [$style.entryDisplay_muted]: $store.getters.selected.length,
    }"
    :data-entry-id="entry.temp ? null : entry.id"
    :data-color="entry.color"
    @click="onClick"
    :tabindex="entry.temp ? -1 : 0"
    @contextmenu.prevent="contextMenu"
    @mousedown="onMouseDown"
    @mouseenter.stop="(e) => (!entry.temp ? onMouseEnter(e, DndInfo) : null)"
    @mouseleave.stop="(e) => (!entry.temp ? onMouseLeave(e) : null)"
    @touchstart="(e) => (!entry.temp ? onTouchStart(e) : null)"
    v-memo="[
      isInitialized,
      isLazyMounted,
      // isVisibleInViewport,
      renderOutput,
      renderType,
      showConfig,
      JSON.stringify(display),
      JSON.stringify(entry),
      JSON.stringify(availableStatuses),
      JSON.stringify(customFields),
      configX,
      configY,
      $slots,
      isSelected,
      $store.getters.selected.length,
      isDragged,
      $route.params.id,
    ]"
    :style="cssProps"
  >
    <ButtonComponent
      v-if="position == 'undo' && entry.deleted_at"
      color="red"
      class="mb-1.5 w-full"
    >
      <TrashIcon class="h-4 w-4" /> Deleted
    </ButtonComponent>

    <CoverDisplay
      v-if="isLazyMounted && renderType == 'gallery' && entry.cover"
      :modelValue="entry.cover"
      :entry="entry"
      class="mb-2 h-24 w-full overflow-hidden rounded-lg"
    />

    <div class="flex gap-x-2.5">
      <div
        v-if="isRenderLeftColumn && isLazyMounted"
        :class="{
          [$style.leftColumn]: true,
          // 'pt-0.5': ['sm', 'md'].includes(size), // in case name is bigger
          // 'pt-1': ['lg', 'xl', '2xl'].includes(size), // in case name is bigger
          // 'pt-3': ['4xl'].includes(size), // in case name is bigger
        }"
      >
        <div
          v-if="
            computedDisplay.output &&
            !['gallery'].includes(renderType) &&
            entry.output
          "
          class="relative inline-flex items-start"
        >
          <ButtonComponent
            @click.stop="
              (renderOutput = !renderOutput),
                toggleRenderOutput
                  ? toggleRenderOutput(entry, renderOutput)
                  : null
            "
            variant="minimal"
            size="sm"
            :color="entry.color"
            :class="{
              [`${$style.renderOutput}`]: true,
              // 'dark:!text-black':
              //   position == 'left' && entry.id == $route.params.id,
            }"
            class="rounded-full !ring-offset-2 !duration-0"
            data-test="toggle_output"
          >
            <ChevronRightIcon
              size="16"
              stroke-width="1.5"
              :class="{ 'rotate-90': renderOutput }"
              class="transition-transform duration-200"
            />
            <!-- <IconHelper
                name="chevron-right"
                size="16"
                stroke-width="1.5"
                :class="{ 'rotate-90': renderOutput }"
                class="transition-transform duration-200"
              /> -->
          </ButtonComponent>
        </div>
        <div :class="$style.statusAndSensesWrapper">
          <StatusDisplay
            v-if="computedDisplay.status && entry.status_id"
            v-model="status"
            :entry="entry"
            :statuses="availableStatuses"
            :disabled="disabled"
            :position="position"
            :size="size"
            @click.stop="(e) => $emit('click', e)"
            :class="$style.statusDisplay"
          />
          <SensesDisplay
            v-if="
              renderType != 'tiles' &&
              computedDisplay.senses &&
              entry.senses?.length
            "
            :class="{
              [`${$style.sensesDisplay}`]: true,
              // 'flex-col pb-1.5': position != 'left',
              // '-ml-1.5': position == 'left',
              // 'pt-2': [/*'lg', */ 'xl', '2xl', '4xl'].includes(size), // in case name is bigger
            }"
            class="self-end"
            v-model="entry.senses"
            :entry="entry"
            :position="position"
            :disabled="disabled || position == 'calendar'"
          />
        </div>
      </div>

      <div key="center" :class="$style.centerColumn">
        <div :class="$style.centerColumn_firstRow">
          <PriorityDisplay
            v-if="computedDisplay.priority && entry.priority"
            v-model="entry.priority"
            :entry="entry"
            :position="position"
            :class="$style.priorityDisplay"
          />
          <ProcrastinationDisplay
            v-if="
              isLazyMounted &&
              computedDisplay.procrastination &&
              entry.procrastination
            "
            v-model="entry.procrastination"
            :entry="entry"
            :position="position"
            :class="$style.procrastinationDisplay"
          />
          <NameDisplay
            v-if="isLazyMounted"
            v-model="entry"
            :entry="entry"
            :placeholder="placeholder"
            :disabled="
              disabled ||
              position == 'calendar' ||
              position == 'template' ||
              (!entry.temp &&
                (($windowSize.width < 768 &&
                  $route.params.id != entry.id &&
                  $route.params.id2 != entry.id) ||
                  !['center', 'bound', 'modal'].includes(position) ||
                  $store.getters.dragged.length > 0))
            "
            :class="{
              'line-clamp-1': ['left', 'calendar'].includes(position),
              'line-clamp-2': ['focus'].includes(position),
              '_min-w-full_ line-clamp-2': position == 'undo',
              'mb-2': size == '4xl',
            }"
            class="w-full flex-1"
            @onKeyUp="
              (name, event) => {
                this.$emit('onNameKeyUp', name, event);
              }
            "
            @onEnter="
              () => {
                this.$emit('onNameChanged', entry.name);
              }
            "
            @onFocus="() => $emit('onFocus')"
            :size="size"
            :position="position"
            :data-tour="
              position == 'center' && entry.id == $route.params.id
                ? 'entry_name'
                : null
            "
            :style="{
              '--ps-name-display-color': 'var(--ps-entry-display-color)',
            }"
          />
          <div
            v-else
            :class="[$style.skeletonLoader, $style.skeletonLoader_name]"
          />

          <LinksDisplay
            v-if="
              // (position != 'center' || $route.params.id != entry.id) &&
              // renderType != 'gallery' &&
              computedDisplay.links && entry.links
            "
            v-model="entry"
            :entry="entry"
            :position="position"
            :class="{
              '-mt-0.5': ['sm'].includes(size) && position != 'calendar',
              'mt-0.5': ['md'].includes(size),
              'pt-1': ['lg', 'xl', '2xl'].includes(size), // in case name is bigger
              'pt-2': ['4xl'].includes(size), // in case name is bigger
              'order-last -mx-1 basis-full':
                renderType == 'board' || ['search'].includes(position),
            }"
          />
        </div>
        <slot name="description">
          <DescriptionDisplay
            v-if="
              isLazyMounted && computedDisplay.description && entry.description
            "
            v-model="entry.description"
            :entry="entry"
            :position="position"
          />
        </slot>

        <div
          v-if="
            isLazyMounted &&
            ((computedDisplay.schedule &&
              (entry.schedule || $slots.schedule)) ||
              (computedDisplay.time_trackings &&
                (entry.time_trackings?.length || $slots.time_trackings)) ||
              (computedDisplay.routine && (entry.routine || $slots.routine)))
          "
          :class="$style.centerColumn_thirdRow"
        >
          <ScheduleDisplay
            v-if="computedDisplay.schedule && entry.schedule"
            v-model="entry.schedule"
            :entry="entry"
            @click="
              (e) => $emit('click', e) // @note for input display to recognize the click event
            "
            :disabled="disabled"
          />

          <TimeTrackingsDisplay
            v-if="
              computedDisplay.time_trackings &&
              !['gallery'].includes(renderType) &&
              entry.time_trackings?.length
            "
            v-model="entry.time_trackings"
            :entry="entry"
          />

          <RoutineDisplay
            v-if="computedDisplay.routine && entry.routine"
            v-model="entry.routine"
            :entry="entry"
            :disabled="disabled"
            :class="{
              // 'ml-auto': true,
              // 'absolute bottom-2 right-2': renderType == 'gallery',
            }"
            class="ml-auto"
          />
        </div>
        <CustomFieldsDisplay
          v-if="
            isLazyMounted &&
            computedDisplay.custom_fields &&
            customFields?.length
          "
          v-model="entry"
          :entry="entry"
          :customFields="customFields"
          class="mt-1"
        />
      </div>

      <div
        v-if="isLazyMounted"
        :class="$style.rightColumn"
        class="relative ml-2 inline-flex items-start"
      >
        <slot name="top-right" />

        <OutputCount
          v-if="computedDisplay.output_count && entry.output"
          :entry="entry"
          class="w-6 text-center text-sm text-neutral-500 group-hover:hidden"
        />

        <button
          v-if="
            !(
              ($route.params.id == entry.id && position == 'center') ||
              ($route.params.id2 == entry.id && position == 'modal')
            ) &&
            computedDisplay.settings &&
            !entry.temp &&
            ($route.params.id != entry.id || position == 'left')
          "
          type="button"
          @click.stop="showConfig = true"
          :class="{
            'absolute z-10 opacity-0 focus-visible:bg-white focus-visible:opacity-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-900 group-hover:md:opacity-100 dark:focus-visible:bg-black dark:focus-visible:ring-neutral-100':
              position == 'left',

            'text-neutral-200 hover:!bg-white/10 focus-visible:bg-neutral-900 focus-visible:ring-neutral-200 dark:text-neutral-700  dark:focus-visible:bg-neutral-200':
              position == 'left' && isActive,
            'mt-0.5': position != 'left',
            'mt-2': ['4xl'].includes(size), // in case name is bigger
          }"
          class="ps_settings_button right-0 flex w-5 justify-center rounded-full text-gray-700 hover:bg-black/5 dark:text-neutral-400 dark:hover:bg-white/5"
          data-test="entry_settings"
        >
          <EllipsisIcon size="20" strokeWidth="1.25" />
        </button>
      </div>
      <!-- </TransitionGroupHelper> -->
    </div>
    <!-- <slot name="after" /> -->
    <Teleport v-if="isLazyMounted" :disabled="!showConfig" to="body">
      <OverlayComponent
        v-if="showConfig"
        @click.stop="showConfig = false"
        data-test="contextmenu_overlay"
        class="z-[99999]"
      />

      <Transition @enter="animationEnterContextmenu" :css="false">
        <div
          v-if="showConfig"
          ref="configForm"
          @submit.prevent
          :style="{
            left: configX + 'px',
            top: configY + 'px',
          }"
          class="fixed z-[99999] max-h-[500px] max-w-fit cursor-auto"
        >
          <EntryContextmenu
            ref="configuration"
            v-model="entry"
            :hide="['timestamps']"
            @mouseup.stop
            @mousedown.stop
            @touchstart.stop
            @touchend.stop
            @close="showConfig = false"
            :shareNode="$refs.wrapper"
            :position="position"
          >
            <slot name="contextmenu" />
          </EntryContextmenu>
        </div>
      </Transition>
    </Teleport>

    <!-- Start: Draggable Placeholder -->
    <div
      v-if="
        $store.getters.dragged.findIndex((e) => e.id == entry.id) !== -1 &&
        position != 'draggable' &&
        position != 'calendar' &&
        position != 'undo'
      "
      class="absolute inset-0 z-40 rounded bg-neutral-50 dark:bg-neutral-900"
    />
    <!-- End: Draggable Placeholder -->
  </div>
</template>

<script>
import { Applicators } from "../mixins/Applicators";
import { DragAndDrop } from "../mixins/DragAndDrop";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Sorting } from "../mixins/Sorting";
import { Statusing } from "../mixins/Statusing";
import { Routing } from "../mixins/Routing";
// import { Lazying } from "../mixins/Lazying";
// import { Viewporting } from "../mixins/Viewporting";

import EntryContextmenu from "./partials/EntryContextmenu.vue";
import { ChevronRightIcon, EllipsisIcon } from "lucide-vue-next";
import { defineAsyncComponent, hydrateOnIdle, hydrateOnInteraction } from "vue";

export default {
  inject: {
    overrideEntries: {
      default: null,
    },
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    EntryContextmenu,
    ChevronRightIcon,
    EllipsisIcon,
    OutputCount: defineAsyncComponent({
      loader: () => import("@/components/output/partials/OutputCount.vue"),
      hydrate: hydrateOnIdle,
    }),
    SensesDisplay: defineAsyncComponent({
      loader: () => import("@/components/senses/SensesDisplay.vue"),
      hydrate: hydrateOnInteraction("click"),
    }),
  },
  mixins: [
    Schema,
    Filtering,
    Grouping,
    Sorting,
    Statusing,
    DragAndDrop,
    Scheduling,
    Routining,
    Applicators,
    Routing,
    // Lazying,
    // Viewporting,
  ],
  props: {
    modelValue: Object,
    placeholder: String,
    position: String,
    display: Object,
    disabled: Boolean,
    renderType: String,
    disableRouting: Boolean,
    containingOutputGroup: String,
    size: {
      type: String,
      default: "md",
    },
    toggleRenderOutput: Function,
    isRenderOutput: Boolean,
    entryIndex: Number,
  },
  mounted() {
    // console.timeEnd("create->mounted: " + this.modelValue.name);
    this.dataDisplay = this.display;
    this.availableStatuses = this.availableStatusesComputed;
    this.customFields = this.customFieldsComputed;
    // setTimeout(() => {
    this.pullData();
    // }, 1000);
    // this.$onIdle(() => {
    // setTimeout(() => {
    this.isLazyMounted = true;
    // }, 0);
    // });
    // this.entries = this.$store.getters.entries.filter(
    //   (e) => e.deleted_at === null,
    // );
    // this.children = this.filterEntries(this.outputSchema(this.entry))
    //   ?.filter(this.filterGroups(this.entry.output?.grouping))
    //   ?.sort(this.sortEntries(this.entry.output?.sorting));
  },

  // // eslint-disable-next-line no-unused-vars
  // renderTracked(event) {
  //   // console.trace();
  //   console.log("renderTracked e", this.entry.name, this.position, event);
  // },
  // // eslint-disable-next-line no-unused-vars
  // renderTriggered(event) {
  //   // console.trace();
  //   console.log("renderTriggered e", this.entry.name, this.position, event);
  // },
  data() {
    return {
      isInitialized: true,
      isVisibleInViewport: false,
      dataDisplay: {},
      defaultDisplay: {
        name: true,
        status: true,
        procrastination: true,
        description: true,
        time_trackings: true,
        priority: true,
        routine: true,
        senses: true,
        schedule: true,
        output: true,
        input: true,
        links: true,
        columns: true,
        leftover: true,
        settings: true,
        custom_fields: true,
      },

      showOutput: false,
      showConfig: false,
      configX: null,
      configY: null,

      // entries: [],
      // children: [],

      availableStatuses: [],
      customFields: [],
      renderOutput: this.isRenderOutput,
      isSelected: false,
      isDragged: false, // $store.getters.dragged.findIndex((e) => e.id == entry.id) === -1
      isLazyMounted: false,
    };
  },
  computed: {
    computedDisplay() {
      return {
        ...this.defaultDisplay,
        ...this.dataDisplay,
      };
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set() {
        //this.$emit("update:modelValue", value);
      },
    },

    space() {
      return this.$store.getters.space;
    },
    // entries() {
    //   return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    // },
    // children() {
    //   return this.filterEntries(this.outputSchema(this.entry))
    //     .filter(this.filterGroups(this.entry.output?.grouping))
    //     .sort(this.sortEntries(this.entry.output?.sorting));
    // },
    status: {
      get() {
        return this.getStatusById(this.entry.status_id);
      },
      set(status) {
        this.setStatus(this.entry, status);
        if (!this.entry.temp)
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
          });
      },
    },
    customFieldsComputed() {
      return this.entry.links
        ?.map((id) => this.$store.getters.entries.find((e) => e.id == id))
        .filter((e) => e?.custom_fields?.length)
        .flatMap((e) => e.custom_fields);
    },
    availableStatusesComputed() {
      if (this.overrideStatuses) return this.overrideStatuses;
      return this.getAvailableStatuses(this.entry);
    },
    isActive() {
      return this.entry.id == this.$route.params.id;
    },
    isRenderLeftColumn() {
      return (
        (this.computedDisplay.output &&
          !["gallery"].includes(this.renderType) &&
          this.entry.output) ||
        (this.computedDisplay.status && this.entry.status_id) ||
        (this.renderType != "tiles" &&
          this.computedDisplay.senses &&
          this.entry.senses?.length)
      );
    },
    cssProps() {
      return {
        "--ps-entry-display-color": this.entry.color
          ? "var(--ps-color-" + this.entry.color + "-500)"
          : null,
      };
    },
  },
  methods: {
    pullData() {
      if (this.entry.output) {
        // this.$nextTick(() => {
        this.$store.dispatch("pull", {
          filters: this.entry.output.data,
          source: {
            file: "EntryDisplay",
            entry: this.entry.name,
          },
        });
        // });
      }
    },
    onClick(e) {
      if (!this.entry.temp) {
        if (
          this.$route.params.id != this.entry.id &&
          this.$route.params.id2 != this.entry.id &&
          (e.shiftKey ||
            e.ctrlKey ||
            (navigator.userAgent.includes("Mobile") &&
              this.$store.getters.selected.length > 0))
        ) {
          // Bulk Selection
          this.$store.dispatch("selected", this.entry);
          return;
        }
        if (e.altKey) {
          // Open in new tab
          window.open(window.location.origin + "/" + this.entry.id, "_blank");
          return;
        }

        if (
          /*(!this.showOutput || this.position == "left") &&*/
          !this.disableRouting &&
          !window.getSelection().toString().length
        ) {
          this.navigateTo(this.entry);
        }
      }
    },

    contextMenu(e, settingsClicked = false) {
      if (this.entry.temp) {
        return;
      }

      if (this.$route.params.id == this.entry.id && this.position == "center") {
        return;
      }

      /**
       * pointerType is undefined in cypress e2e tests
       */
      if (e.pointerType == "mouse" || typeof e.pointerType == "undefined") {
        if (e.type == "contextmenu" || settingsClicked) {
          /**
           * stopPropagation is needed to
           * prevent the contextmenu event
           * from potential parent entry
           */
          e.stopPropagation();
          e.preventDefault();

          if (this.computedDisplay.settings) {
            this.mousePositionOnDragStart.x = null;
            this.mousePositionOnDragStart.y = null;

            this.showConfig = !this.showConfig;
          }
        }
      } else {
        // no contextmenu on touch devices
        // if (this.showConfigOnPress == this.showConfig)
        //   this.showConfig = !this.showConfig;
        // e.stopPropagation();
      }
    },
    updateEntry() {
      this.$store.dispatch("push", {
        event: "entry_update",
        params: { entry: this.entry },
        entry: this.entry,
      });
    },
    animationEnterContextmenu(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done,
      });
    },
  },
  watch: {
    display: {
      handler: function (n, o) {
        if (JSON.stringify(n) != JSON.stringify(o)) {
          this.dataDisplay = n;
        }
      },
      immediate: true,
    },
    "$store.getters.selected": function (n) {
      const isSelected = n.findIndex((id) => id == this.entry.id) !== -1;
      if (isSelected != this.isSelected) this.isSelected = isSelected;
    },
    "$store.getters.dragged": function (n) {
      const isDragged = n.findIndex((e) => e.id == this.entry.id) !== -1;
      if (isDragged != this.isDragged) this.isDragged = isDragged;
    },
    availableStatusesComputed: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) this.availableStatuses = n;
    },
    customFieldsComputed: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) this.customFields = n;
    },

    "$store.getters.timestamp": function (n, o) {
      if (n - o > 2) {
        this.$onIdle(() => {
          this.pullData();
        });
      }
    },
    showConfig() {
      if (this.showConfig) {
        this.configX = this.$cursorPosition.x;
        this.configY = this.$cursorPosition.y;

        this.$nextTick(() => {
          /**
           * This is used to prevent the config
           * form from overlapping on the right side
           */
          if (
            window.innerWidth <
            this.configX + this.$refs.configForm.offsetWidth
          ) {
            this.configX =
              window.innerWidth - this.$refs.configForm.offsetWidth - 20;
          }
          /**
           * This is used to prevent the config
           * form from overlapping on the bottom side
           */

          if (
            window.innerHeight <
            this.configY + this.$refs.configForm.offsetHeight
          ) {
            this.configY =
              window.innerHeight -
              this.$refs.configForm.offsetHeight -
              window.innerHeight / 10;
          }

          this.configX = Math.max(5, this.configX);
          this.configY = Math.max(5, this.configY);
        });
      }
    },
  },
};
</script>

<style module lang="scss">
.entryDisplay {
  padding: var(--ps-entry-display-padding-y) var(--ps-entry-display-padding-x);
  border-radius: var(--ps-entry-display-border-radius);

  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &_selected {
    @apply ring-1 ring-inset ring-blue-500;
  }
  &_muted {
    > * {
      @apply pointer-events-none;
    }
  }
}

.leftColumn {
  display: flex;
  column-gap: 4px;
  padding-top: max(0px, var(--ps-name-display-line-height) / 2 - 12px);

  .statusAndSensesWrapper {
    &:empty {
      display: none;
    }
  }

  > * {
    @apply relative flex flex-col items-center justify-between gap-y-1.5;

    /* the following is for cautiosly adding the right padding and margin to make sure the first element is on level with the name */

    // outline: 1px solid blue;
    // > *:first-child {
    //   transform: translateY(-50%);
    // }

    .renderOutput {
      &:only-child {
        /* this will make the render output display to be centered */
        margin-top: 5px;
        margin-left: -5px;
      }
    }
    .statusDisplay {
      padding-left: 2px;
    }
    .sensesDisplay {
      &:only-child {
        /* this will make the senses display to be centered when no status display is infront */
        margin-top: 7px;
      }
    }
  }
}

.centerColumn {
  @apply flex min-w-0 flex-1 flex-grow flex-col flex-wrap justify-start gap-y-0;

  &_firstRow {
    @apply flex w-full flex-wrap items-start gap-x-1.5 gap-y-0 leading-normal;
  }
  &_thirdRow {
    @apply /*gap-y-2*/ flex w-full flex-wrap items-center gap-x-5;
  }
}

.procrastinationDisplay,
.priorityDisplay {
  padding-top: max(0px, var(--ps-name-display-line-height) / 2 - 12px);
  // @apply ring;
  // line-height: var(--ps-name-display-line-height);
}

@container calendarEventContent (width < 96px) {
  .leftColumn {
    display: none;
  }
}
@container calendarEventContent (height) {
  .leftColumn > * {
    flex-direction: row !important;
    align-items: start !important;
    // .statusDisplay {
    //   width: 14px;
    // }
  }
  .sensesDisplay {
    flex-direction: row !important;
    align-self: start !important;
    margin-top: 5px;
    &:not(:only-child) {
      margin-left: 8px;
    }
  }
  .rightColumn {
    display: none;
  }
}

.skeletonLoader {
  // @apply w-full flex-1 animate-pulse rounded-md bg-neutral-200;
  composes: skeletonLoader from "@/styles/skeleton-loader.module.scss";
  &_name {
    @apply h-6;
  }
}
</style>
