<template>
  <section
    class="flex items-start gap-x-2"
    data-test="entry_description_section"
  >
    <div
      v-if="description"
      class="flex w-full gap-x-1"
      @click="
        (e) => {
          editable ? e.stopPropagation() : null;
        }
      "
      data-test="entry_description_content"
    >
      <IconHelper
        name="text"
        v-if="!['focus', 'bound'].includes(position)"
        size="18"
        stroke-width="1.25"
        class="mt-0.5 dark:text-neutral-500"
      />
      <TextEditor
        id="description_editor"
        v-model="description.content"
        ref="description_editor"
        @update:modelValue="
          (html) => {
            description = { ...description, ...{ content: html } };
          }
        "
        :editable="editable"
        :class="{
          [`${$style.textEditor}`]: true,
          'line-clamp-2': !editable && !['bound'].includes(position),
          // 'line-clamp-4': !editable && ['bound'].includes(position),
        }"
        data-test="entry_description_content_editor"
        placeholder="Write a Description..."
      />
    </div>
  </section>
</template>

<script>
import TextEditor from "../helpers/TextEditor.vue";

export default {
  props: {
    modelValue: Object,
    entry: Object,
    position: String,
  },
  components: { TextEditor },
  computed: {
    editable() {
      return (
        (this.$route.params.id == this.entry.id &&
          ["center"].includes(this.position)) ||
        (this.$route.params.id2 == this.entry.id &&
          ["modal"].includes(this.position))
      );
    },
    description: {
      get() {
        return this.modelValue;
      },
      set(description) {
        if (description.content.length && description.content != "<p></p>") {
          // eslint-disable-next-line vue/no-mutating-props
          this.entry.description = description;
          // eslint-disable-next-line vue/no-mutating-props
        } else this.entry.description = null;
        if (!this.entry.temp)
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
            undo: 1,
          });
      },
    },
  },
};
</script>

<style scoped>
section:not(:has([contenteditable="true"])) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>

<style module lang="scss">
.textEditor {
  @apply min-w-[1rem] flex-1 break-words text-sm leading-relaxed text-neutral-600 dark:text-neutral-400;
  word-break: break-word;
}
</style>
