<template>
  <div class="flex items-center">
    <div ref="reference" class="flex flex-1 items-center">
      <TextInput
        ref="input"
        v-model="input"
        @onKeyUp="(string, event) => searchEntries(string, event)"
        @onEnter="
          $refs.output?.entries?.length
            ? select($refs.output.entries[focusIndex])
            : null
        "
        @onBlur="onBlur"
        :placeholder="placeholder"
        :noEnterOnBlurWhileOnMobile="true"
        class="block w-full overflow-hidden whitespace-nowrap border-0 py-0 pl-1 pr-0 text-neutral-900 ring-0 placeholder:text-gray-400 focus:ring-0 sm:text-sm dark:text-neutral-100"
        data-test="search"
      />
    </div>

    <!-- @onBlur="onBlur" -->
    <SearchResults
      ref="floating"
      :query="query"
      @onEntrySelect="
        (entry) => {
          clear();
          if (enableRouting)
            $router.push({ name: 'entry', params: { id: entry.id } });
          else select(entry);
        }
      "
      :style="floatingStyles"
      class="max-h-[300px] w-[300px] overflow-y-auto md:w-[350px] lg:w-[400px]"
      data-test="search_results"
      :filterSearchResults="filterSearchResults"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
import TextInput from "../helpers/TextInput.vue";
import SearchResults from "../partials/SearchResults.vue";

export default {
  name: "SearchComponent",
  components: { TextInput, SearchResults },
  props: {
    clearOnSelect: Boolean,
    clearOnBlur: Boolean,
    placeholder: String,
    filterSearchResults: {
      type: Function,
      default: () => true,
    },
    enableRouting: Boolean,
  },
  data() {
    return {
      output: [],
      query: "",
      input: "",
      timeOut: null,
      focusIndex: 0,
    };
  },
  computed: {
    entries() {
      if (!this.query.length) return [];
      return this.$store.getters.entries
        .filter(
          (e) =>
            e.deleted_at === null &&
            e.name &&
            e.name.toLowerCase().indexOf(this.query.toLowerCase()) !== -1,
        )
        .filter(this.filterSearchResults);
    },
  },
  watch: {
    // query() {
    //   if (this.timeOut) this.timeOut = null;
    //   this.timeout = setTimeout(() => {
    //     this.$store.dispatch("pull", {
    //       filters: [{ key: "name", op: "any", val: this.query.toLowerCase() }],
    //       limit: 10,
    //     });
    //     this.timeOut = null;
    //   }, 750);
    // },
  },
  methods: {
    onBlur() {
      if (4 > 3 && this) return;
      if (this.query.length && this.entries.length) {
        setTimeout(() => {
          this.$emit("onBlur");
          this.clear();
          this.$emit("cancel");
        }, 100);
      } else {
        this.$emit("onBlur");
        this.clear();
        this.$emit("cancel");
      }
    },
    searchEntries(query, event) {
      if (event.key == "Escape") {
        this.query = "";
        this.input = "";
      }
      if (event.key == "ArrowDown" || event.key == "ArrowUp") {
        switch (event.key) {
          case "ArrowDown":
            this.focusIndex++;
            break;
          case "ArrowUp":
            this.focusIndex--;
            break;
        }
        if (this.focusIndex < 0) {
          this.focusIndex = this.entries.length - 1;
        }
        if (this.focusIndex >= this.entries.length) {
          this.focusIndex = 0;
        }
        return;
      }
      if (event.key != "Enter") {
        this.focusIndex = 0;
      }
      this.query = query;
      // if (query && query.length >= 3) {
      //   if (!this.timeOut) {
      //     this.timeOut = setTimeout(() => {
      //       if (this.query.length >= 3) {
      //         this.output = [
      //           { key: "name", op: "any", val: this.query.toLowerCase() },
      //         ];
      //       }
      //       this.timeOut = null;
      //     }, 500);
      //   }
      // }
    },
    select(entry) {
      if (entry) this.$emit("select", entry);
      this.clear();
    },
    clear() {
      if (this.clearOnSelect) {
        this.input = "";
        this.query = "";
        this.$nextTick(() => {
          if (
            this.$refs &&
            this.$refs.input &&
            this.$refs.input.$refs &&
            this.$refs.input.$refs.input
          ) {
            this.$refs.input.$refs.input.innerText = "";
            this.$refs.input.$el.blur();
          }
        });
      }
    },
  },
};
</script>
