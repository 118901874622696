<template>
  <div
    v-if="input"
    @click="editing = true"
    :class="{
      [$style.inputDisplay]: true,
      // 'rounded-lg px-2 py-1.5': !['gallery'].includes(renderType),
      // 'hover:bg-neutral-50 dark:hover:bg-neutral-950':
      //   !['gallery'].includes(renderType) && !disabled,
      [$style.inputDisplay_disabled]: disabled,
      // '-mx-2 -my-1.5 ': position != 'left' && !['gallery'].includes(renderType),
      [$style.inputDisplay_editing]: editing,
    }"
    data-test="entry_input_section"
  >
    <div class="min-h-8">
      <!-- This is for reseting the keyboard suggestions on IPhone and maybe other mobile devices-->
      <input
        type="text"
        ref="mobile-suggestion-fix"
        class="absolute h-0 w-0 opacity-0"
      />
      <EntryDisplay
        ref="entry_display"
        :modelValue="newEntry"
        :placeholder="
          '&plus; ' +
          (entry && entry.name
            ? entry.name.length > 40
              ? entry.name.slice(0, 37) + '...'
              : entry.name
            : 'Add Entry')
        "
        :display="display"
        :renderType="renderType"
        :position="position"
        :disabled="position == 'template'"
        :editable="position != 'template'"
        @onNameKeyUp="(name) => (searchQuery = name)"
        @onNameChanged="createEntry"
        @onFocus="editing = true"
        @click="editing = true"
        :data-input="entry.id"
        :data-test="$slugify(entry?.name) + '_input'"
        data-tour="input_display"
      >
        <template v-slot:top-right>
          <TourHelper
            v-if="
              entry.id &&
              entry.id == $route.params.id &&
              editable &&
              position == 'center'
            "
            hook="input_settings"
            size="sm"
            variant="minimal"
            class="mr-1.5 mt-0.5 h-5"
          />

          <PopoverHelper
            v-if="editable"
            id="input_builder"
            data-test="input_builder"
            class="mt-0.5"
            :teleport="true"
          >
            <template v-slot:button>
              <span
                class="w-5 text-neutral-400 hover:text-black dark:text-neutral-600 dark:hover:text-neutral-300"
                data-tour="input_settings"
              >
                <IconHelper name="settings-2" size="20" />
              </span>
            </template>

            <div class="flex flex-col gap-4 p-3">
              <div
                v-if="Object.keys(overrides).length > 0"
                class="rounded-md bg-blue-50 p-4"
              >
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon
                      class="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                      The following fields have been overridden:

                      <span
                        v-for="key in Object.keys(overrides)"
                        :key="key"
                        class="text-xs font-bold uppercase"
                      >
                        {{ key }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <InputSettings
                id="input_builder_inner"
                v-model="input"
                :entry="entry"
                @click.stop
                class="w-96"
              />
            </div>
          </PopoverHelper>
        </template>
      </EntryDisplay>

      <SearchResults
        v-if="searchQuery.length > 0"
        :query="searchQuery"
        :canHaveNoFocus="true"
        @onEntrySelect="
          (entry) => {
            temporarilyDisableCreateEntry = true;
            applyInput(inputSchema(schema, entry), entry, true);
            buildEntry();
          }
        "
        class="absolute bottom-0 left-0 translate-y-full"
      />

      <div v-if="editing" :class="$style.toolBar">
        <ButtonComponent
          @click.stop="buildEntry(), (editing = false), $emit('onCancel')"
          variant="minimal"
          size="sm"
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          @click.stop="createEntry(), (editing = false)"
          :color="color || 'neutral'"
          :colorWeight="!color || color == 'neutral' ? '900' : '500'"
          title="Create Entry"
        >
          <span> Add Entry </span>
        </ButtonComponent>
      </div>
    </div>
  </div>
  <div v-else>
    <ButtonComponent
      @click="input = addonBlueprint('input', entry, this)"
      data-test="entry_input_create"
      class="w-full"
    >
      <IconHelper name="TextCursorInput" size="20" />
      <span> Add Input </span>
    </ButtonComponent>
  </div>
</template>
<script>
import { addonBlueprint } from "@/addonBlueprint";
import moment from "moment";
import { nanoid } from "nanoid";
import { Schema } from "../mixins/Schema";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { Searching } from "../mixins/Searching";
import { Applicators } from "../mixins/Applicators";

export default {
  inject: {
    overrideEntries: {
      default: null,
    },
  },
  props: {
    modelValue: Object,
    entry: Object,
    schema: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    display: Object,
    position: {
      type: String,
      default: () => {
        return "center";
      },
    },
    renderType: {
      type: String,
      default: () => {
        return "list";
      },
    },
    color: {
      type: String,
      default: () => {
        return "neutral";
      },
    },
  },
  mixins: [Schema, Statusing, Scheduling, Searching, Applicators],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    InformationCircleIcon,
  },
  data() {
    return {
      editing: false,
      newEntry: this.inputSchemaWithId(this.schema),
      scheduleTimer: null,
      output: [],
      focusIndex: 0,
      searchQuery: "",
      temporarilyDisableCreateEntry: false,
      registeredInputId: null,
      addonBlueprint: addonBlueprint,
    };
  },
  computed: {
    input: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    overrides() {
      const overrides = {};
      Object.keys(this.schema).forEach((key) => {
        if (
          JSON.stringify(this.schema[key]) !==
          JSON.stringify(this.entry.input.data[key])
        ) {
          overrides[key] = this.schema[key];
        }
      });
      return overrides;
    },
  },
  mounted: function () {
    this.manageInputRegister();
  },
  beforeUnmount() {
    this.manageInputRegister(false);
  },
  watch: {
    space: function () {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    "$route.params.id": {
      handler(n, o) {
        if (n != o) {
          this.buildEntry();
        }
      },
      immediate: true,
    },
    editing: function (n) {
      // Select the input field when editing
      if (n) {
        if (this.editing && this.disabled) {
          this.editing = false;
        }
        this.$nextTick(() => {
          if (this.editing) {
            this.$el.querySelector("[contenteditable='true']")?.focus();
            this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        });
      }
    },
    /**
     * Disables the creation functionality temporarily.
     * If the `temporarilyDisableCreateEntry` flag is set to `true`, it will be set to `false` after a delay of 200 milliseconds.
     */
    temporarilyDisableCreateEntry: function () {
      if (this.temporarilyDisableCreateEntry) {
        setTimeout(() => {
          this.temporarilyDisableCreateEntry = false;
        }, 0);
      }
    },
    // entries: function () {
    //   this.newEntry = this.inputSchemaWithId(this.schema);
    // },
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.newEntry = this.inputSchemaWithId(this.schema);
        this.manageInputRegister();
      }
    },
    input: function () {
      if (this.input) this.newEntry = this.inputSchemaWithId(this.schema);
    },
    schema: {
      handler() {
        // if (JSON.stringify(n) != JSON.stringify(o)) {
        this.newEntry = this.inputSchemaWithId(this.schema);
        // }
      },
      deep: true,
    },
    "$store.getters.clock": function () {
      this.scheduleUpdater();
    },
  },
  methods: {
    createEntry() {
      const regex =
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

      if (this.editing && regex.test(navigator.userAgent)) {
        /**
         * This is a hack to fix the issue where
         * the keyboard suggestions do not reset
         * on mobile devices after creating an entry.
         */
        const focus = this.$el.querySelector('[contenteditable="true"]:focus');
        if (focus) {
          this.$refs["mobile-suggestion-fix"].focus();
          // setTimeout(() => {
          this.$el.querySelector('[contenteditable="true"]').focus();
          this.$el.scrollIntoView({ behavior: "smooth", block: "center" });
          // }, 200);
        }
      }

      // This got obsolete when Unnamed entries were introduced
      // if (this.newEntry.name.length == 0) return;

      delete this.newEntry.temp;
      this.newEntry.created_at = moment().utc().format("YYYY-MM-DD HH:mm:ss");
      const emitEntry = { ...this.newEntry };

      // Seriously I like the push more, but its anti-pattern
      this.$store.dispatch("addEntry", this.newEntry);
      // this.$store.getters.entries.push(this.newEntry);
      // console.log(this.newEntry);
      this.$store.dispatch("push", {
        event: "entry_create",
        params: { entry: this.newEntry },
        entry: this.newEntry,
        // cb: () => this.$emit("created", emitEntry),
      });

      this.buildEntry();
      this.$emit("created", emitEntry);

      // In case the input is on the left side, we want to close the input after creating an entry
      if (this.position == "left") {
        this.editing = false;
      }
    },
    buildEntry() {
      this.newEntry = this.inputSchemaWithId(this.schema);
      this.searchQuery = "";
    },
    /**
     * Updates the schedule of the new entry based on the schema's schedule.
     * If the schema has a schedule date and time, it interprets them and sets them on the new entry.
     */
    scheduleUpdater() {
      // Check if the schema and new entry have a schedule
      if (this.schema?.schedule && this.newEntry?.schedule) {
        // Check if the schema has a schedule date and the new entry has a schedule date
        if (this.schema.schedule.date?.op && this.newEntry.schedule.date) {
          // Interpret the date from the schema and set it on the new entry
          this.newEntry.schedule.date = this.interpretDate(
            this.schema.schedule,
          );
        }
        // Check if the schema has a schedule time and the new entry has a schedule time
        if (this.schema.schedule.time?.op && this.newEntry.schedule.time) {
          // Interpret the time from the schema and set it on the new entry
          this.newEntry.schedule.time = this.interpretTime(
            this.schema.schedule,
          );
        }
      }
    },
    onInputUpdate() {
      this.newEntry = this.inputSchemaWithId(this.schema);
    },
    inputSchemaWithId(schema) {
      const base = {
        temp: true,
        id: nanoid(),
        space_id: this.space?.id || this.$store.getters.user?.current_space_id,
        status_id: null,
        name: null,
        statuses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
        senses: [],
        links: [],
        backlinks: [],
        created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        completed_at: null,
        deleted_at: null,
      };
      return {
        ...base,
        ...this.inputSchema(schema, base),
      };
    },
    manageInputRegister(add = true) {
      if (this.entry.id && this.position == "center") {
        if (this.registeredInputId) {
          this.$store.dispatch("unregisterInput", {
            id: this.registeredInputId,
            el: this.$el,
            entry: this.entry,
          });
          this.registeredInputId = null;
        }
        if (add) {
          this.registeredInputId = this.$nanoid();
          this.$store.dispatch("registerInput", {
            id: this.registeredInputId,
            el: this.$el,
            obj: this,
            entry: this.entry,
          });
        }
      }
    },
  },
};
</script>

<style module>
.inputDisplay {
  @apply relative flex cursor-pointer flex-col gap-y-2;
  border-radius: var(--ps-entry-display-border-radius);
}

.inputDisplay_disabled {
  @apply !cursor-default;
}
.inputDisplay_editing {
  background-color: var(--ps-base-tinted-color);
  outline: 1px solid var(--ps-base-border-color);
  outline-offset: -1px;
}

.toolBar {
  @apply flex justify-end gap-x-4 p-2;
  border-top: 1px solid var(--ps-base-border-color);
}
</style>
