<template>
  <!-- <transition
      @enter="
        (el, done) => {
          $anime({
            delay: 500,
            targets: el,
            easing: 'easeOutQuad',
            translateX: [100, 0],
            opacity: [0, 1],
            complete: done,
            begin: () => {
              // el.style.position = 'absolute';
            },
          });
        }
      "
      @leave="
        (el, done) => {
          $anime({
            targets: el,
            easing: 'easeOutQuad',
            translateX: [0, -100],
            opacity: [1, 0],
            complete: done,
            begin: () => {
              el.style.width = '100%';
              el.style.position = 'absolute';
            },
          });
        }
      "
    > -->
  <div
    v-if="entry_by_route"
    :key="
      null //entry_by_route.id
      // info: fixes scroll issue with modal rendering on mobile when changing routes
    "
    :data-test="$slugify(entry_by_route.name)"
  >
    <div
      class="mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
    >
      <div class="flex flex-wrap items-center gap-1">
        <ButtonComponent
          v-for="displayOption in Object.keys(displaySettings)"
          :key="displayOption"
          @click="
            displaySettings[displayOption] = !displaySettings[displayOption]
          "
          :class="{
            'opacity-50': !displaySettings[displayOption],
          }"
          size="xs"
        >
          {{ displayOption }}
        </ButtonComponent>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <div
          v-for="size in ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl']"
          :key="size"
        >
          <div class="p-4 ring-1 ring-neutral-100 dark:ring-neutral-700">
            <span> Size: {{ size }}</span>
            <EntryDisplay
              :modelValue="entry_by_route"
              :entry="entry_by_route"
              :display="displaySettings"
              :style="{
                '--ps-name-display-font-size': `var(--ps-font-size-${size})`,
                '--ps-name-display-line-height': `var(--ps-line-height-${size})`,
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <EntrySettings
      v-if="3 > 4"
      v-model="entry_by_route"
      @showSettings="showSettings = true"
      position="center"
      data-test="entry_page"
      data-tour="entry_page"
    />
  </div>
  <!-- </transition> -->
</template>

<script>
import { Statusing } from "./mixins/Statusing";
import { Scheduling } from "./mixins/Scheduling";
import { Routining } from "./mixins/Routining";
import { Lazying } from "./mixins/Lazying";
export default {
  provide() {
    // use function syntax so that we can access `this`
    return {
      entry: this.entry_by_route,
      depth: 0,
    };
  },
  mixins: [Statusing, Scheduling, Routining, Lazying],
  watch: {
    entry_by_route: function () {
      this.showSettings = false;
      if (this.entry_by_route)
        document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
      else {
        // setTimeout(() => {
        //   if (!this.entry_by_route) this.$router.push({ name: "home" });
        // }, 500);
      }

      // setTimeout(() => {
      //   this.showSettings = false;
      // }, 500);
    },
    "entry_by_route.name": function () {
      if (this.entry_by_route)
        document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
    },
    "$route.params.id": function () {
      this.pullData();
    },
    "$route.params.id2": function (id2) {
      /**
       * On closing a modal, we check if the id2
       * is now empty, and set the old title back
       */
      if (!id2) {
        if (this.entry_by_route) {
          document.title = `${this.entry_by_route.name || "Unnamed"} · Pensive`;
        }
      }
    },
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.pullData();
      });
    },
  },
  computed: {
    anchors() {
      return this.entries
        .filter((e) => e.anchor)
        .sort((a, b) => {
          if (a.anchor.position > b.anchor.position) return 1;
          if (a.anchor.position < b.anchor.position) return -1;
          return 0;
        });
    },
    status: {
      get() {
        return this.getStatusById(this.entry_by_route.status_id);
      },
      set(status) {
        this.setStatus(this.entry_by_route, status);
      },
    },

    statuses() {
      return this.getAvailableStatuses(this.entry_by_route);
    },
    user() {
      return this.$store.getters.user;
    },
    entry_by_route() {
      return this.entries?.find((e) => e.id == this.$route.params.id);
    },
    entry2() {
      return this.entries?.find((e) => e.id == this.$route.params.id2);
    },
    entries() {
      return this.$store.getters.entries?.filter((e) => e.deleted_at === null);
    },
    defaultOutput() {
      return [{ key: "links", op: "any", val: [this.entry_by_route.id] }];
    },
    outputIsModified() {
      return (
        this.entry_by_route.output &&
        JSON.stringify(this.defaultOutput) !=
          JSON.stringify(this.entry_by_route.output.data)
      );
    },

    linkedEntries() {
      return this.entry_by_route.links
        .map((id) => this.entries.find((e) => e.id == id))
        .filter((e) => e);
    },
  },
  data() {
    return {
      window: window,
      schema: null,

      displaySettings: {
        name: true,
        status: true,
        procrastination: true,
        description: true,
        time_trackings: true,
        priority: true,
        routine: true,
        senses: true,
        schedule: true,
        output: true,
        input: true,
        links: true,
        columns: true,
        leftover: true,
        settings: true,
        custom_fields: true,
      },
    };
  },
  created: function () {
    this.pullData();
    if (this.entry_by_route)
      document.title = `DEBUG // ${
        this.entry_by_route.name || "Unnamed"
      } · Pensive`;
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    pullData() {
      if (this.$route.name == "entry") {
        if (this.entry_by_route?.deleted_at === null) {
          this.$store.dispatch("pull", {
            filters: [{ key: "id", op: "eq", val: this.entry_by_route.id }],
          });
        } else {
          this.$router.push({ name: "home" });
        }
      }
    },
  },
};
</script>
